.accordion {
  @apply flex relative text-left justify-between items-start w-full
  select-none font-roboto-serif font-semibold pb-5 leading-none;

  &.inactive {
    @apply border-b-[0.5px] border-solid border-pn-black-base;
  }

  ~ .accordion {
    @apply mt-5;
  }

  &:not(.accordion-ui) {
    + .panel {
      display: none;

      &.active {
        display: block;
      }
    }
  }
}

.accordionTitle {
  max-width: 95%;
}

.accordion-ui {
  @apply border-b-[0.5px] border-solid border-pn-black-base;

  + .panel {
    max-height: 0;
    opacity: 0;
    transition: all 0.25s ease-out;
    overflow: hidden;
  }

  + .panel.active {
    max-height: 999999999999999px; // Adjust according to your content size
    opacity: 1;
    @apply border-b-[0.5px] border-solid border-pn-black-base;
    transition: all 0.125s ease;
    @apply py-10 border-0;
  }
}

.store + .store {
  margin-top: 1.5em;
}
